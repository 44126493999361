import { useAppSelector, useLocalStorage } from '@thriveglobal/thrive-web-core'
import { useCallback, useMemo } from 'react'
import { AchievementV3Progress } from '../../../graphql/generated/autogenerated'

const LOCAL_STORAGE_KEY = 'ACHIEVEMENTS_PREVIOUS_PROGRESS'

export type PreviousAchievementsProgress = {
    updatedAt: string
    total: number
    progress: {
        upc: string
        progress: number
    }[]
}

const createPreviousAchievementsProgress = (
    progress?: AchievementV3Progress[]
): PreviousAchievementsProgress => ({
    updatedAt: new Date().toISOString(),
    total: progress?.reduce((acc, p) => acc + p.total, 0) || 0,
    progress:
        progress?.map((p) => ({
            upc: p.upc,
            progress: p.currentProgress
        })) || []
})

export const usePreviousAchievementsV3Progress = () => {
    const { progressV3 } = useAppSelector((state) => state.achievement)
    const [previousProgress, setPreviousProgress] =
        useLocalStorage<PreviousAchievementsProgress | null>(
            LOCAL_STORAGE_KEY,
            null
        )

    const setCurrentProgress = useCallback(async () => {
        const progress = createPreviousAchievementsProgress(progressV3)
        setPreviousProgress(progress)
    }, [progressV3, setPreviousProgress])

    const newAchievementsCount = useMemo(() => {
        const currentTotal =
            progressV3?.reduce((acc, p) => acc + p.total, 0) || 0

        if (!previousProgress) {
            return currentTotal
        }

        const previousTotal = previousProgress?.total || 0

        return currentTotal - previousTotal
    }, [previousProgress, progressV3])

    return useMemo(
        () => ({
            previousProgress,
            setCurrentProgress,
            newAchievementsCount
        }),
        [newAchievementsCount, previousProgress, setCurrentProgress]
    )
}
