import { Box } from '@mui/material'
import React, { memo, Suspense, useMemo } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { withErrorBoundary } from '../../../../../components/elements/ErrorBoundaryWrapper'
import {
    ProductInfo,
    UserPlantInfo
} from '../../../../../graphql/generated/autogenerated'
import { Image } from '../../../../components/elements'
import { usePlantIcons } from '../../../../hooks'
import PlantInfoTooltip from '../PlantInfoTooltip/PlantInfoTooltip'
import PlantFallback from './PlantFallback'

const ACCESSIBILITY_MESSAGE = defineMessage({
    defaultMessage: '{plantName} - Step {progress}/{total}',
    description: 'title for plant'
})

export type PlantProps = {
    index?: number
    plant?: UserPlantInfo
    product?: ProductInfo
    showSway?: boolean
    showDirt?: boolean
    isPreview?: boolean
}

const Plant: React.FC<PlantProps> = ({
    index,
    plant,
    product,
    showSway = true,
    showDirt = true,
    isPreview = false
}) => {
    const { formatMessage, formatNumber } = useIntl()
    const { getPlantIcon, getEmptyStatePlant } = usePlantIcons()
    const [tooltipOpen, setTooltipOpen] = React.useState(false)

    const handleToggleTooltip = () => setTooltipOpen(!tooltipOpen)

    const memoizedPlant = useMemo(() => plant, [plant])
    const memoizedProduct = useMemo(() => product, [product])

    if ((!memoizedPlant || !memoizedProduct) && !isPreview) {
        const EmptyPlantIcon = getEmptyStatePlant(index)
        return (
            <EmptyPlantIcon
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            />
        )
    }

    if (memoizedPlant?.plantImage) {
        return (
            <PlantInfoTooltip
                open={tooltipOpen}
                setOpen={setTooltipOpen}
                challengeName={memoizedPlant?.challengeName}
                dateAdded={memoizedPlant?.createdAt}
            >
                <Box
                    onClick={handleToggleTooltip}
                    width="100%"
                    height="100%"
                    sx={{ cursor: 'pointer' }}
                >
                    <Image
                        data-testid="plant-image"
                        src={memoizedPlant?.plantImage}
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                    />
                </Box>
            </PlantInfoTooltip>
        )
    } else {
        const currentPlant = isPreview
            ? ({
                  productUpc: memoizedProduct.upc,
                  progressSteps: memoizedProduct.plantWateringSteps
              } as UserPlantInfo)
            : memoizedPlant
        const PlantIcon = getPlantIcon(currentPlant)
        const accessibilityTitle = formatMessage(ACCESSIBILITY_MESSAGE, {
            plantName: product.title,
            progress: formatNumber(currentPlant.progressSteps),
            total: formatNumber(memoizedProduct.plantWateringSteps)
        })

        return (
            <Suspense fallback={<></>}>
                <PlantIcon
                    key={currentPlant?.id}
                    titleAccess={accessibilityTitle}
                    showsway={String(showSway)}
                    showDirt={showDirt}
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                />
            </Suspense>
        )
    }
}

export default withErrorBoundary(
    'Plant',
    'Component',
    memo(Plant),
    PlantFallback
)
